/* MESSAGE 
================================================== */
.tl-message, .tl-message-full {
	width:				100%;
	//max-width: 			200px;
	height:				100%;
	position: 			absolute;
	//position: 			relative;
	
	display: 			table;
	overflow: 			hidden;
	top: 				0px;
	left: 				0px;
	z-index:			99;
	margin:				auto;
	text-align:			center;
	.tl-message-container {
		padding: 			20px;
		margin:20px;
		text-align:			center;
		vertical-align: 	middle;
		display:table-cell;

		.tl-message-content {
			color:#666;
			text-align: center;
			font-size: 11px;
			line-height: 13px;
			text-transform: uppercase;
			margin-top: 7.5px;
			margin-bottom: 7.5px;
			text-shadow: 1px 1px 1px #FFF;
			
			strong {
				text-transform: uppercase;
			}
		}
		.tl-loading-icon {
			width: 30px;
			height: 30px;
			background-color: #666;
			vertical-align: middle;
			.box-shadow(inset 0 1px 2px rgba(255,255,255,.1));
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		  //margin: 100px auto;
			-webkit-animation: rotateplane 1.2s infinite ease-in-out;
			animation: rotateplane 1.2s infinite ease-in-out;
		}

		@-webkit-keyframes rotateplane {
			0% { -webkit-transform: perspective(120px) }
			50% { -webkit-transform: perspective(120px) rotateY(180deg) }
			100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
		}

		@keyframes rotateplane {
			0% { transform: perspective(120px) rotateX(0deg) rotateY(0deg) }
			50% { transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) }
			100% { transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg) }
		}
	}
}
.tl-message-full {
	.translucent-background(#FFF, 0.80);
	[class^="tl-icon-"], [class*=" tl-icon-"] {
		//width: 48px;
		//height: 48px;
		color:#666;
		font-size:72px;
	}
	.tl-message-container {
		.tl-message-content {
			font-size: 22px;
			line-height: 22px;
			text-shadow: none;
			color:#666;
			text-transform: none;
			font-weight: normal;
			.tl-button {
				display:inline-block;
				cursor:pointer;
				background-color:#FFF;
				color:#333;
				padding:10px;
				margin-top:10px;
				.border-radius(7px)
			}
		}
	}

}
.tl-message {
	
}