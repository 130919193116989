.tl-media {
	.tl-media-wikipedia {
		//font-size: @base-font;
		//line-height: @base-line;
		text-align:left;
		margin-left: auto;
		margin-right: auto;
		//margin-bottom:@base-space;
		clear:both;
		.tl-icon-wikipedia {
			font-size:32px;
			margin-right:10px;
			//margin-bottom:10px;
			float:left;
			padding-top:3px;
			//padding-right:10px;

			//border-right: 1px solid @ui-background-color-darker;
		}
		.tl-wikipedia-pageimage {
			float:left;
			margin-right:10px;
			margin-bottom:5px;
			margin-top:5px;
		}
		.tl-wikipedia-title {
			margin-left:60px;
			padding-left:10px;
			border-left: 1px solid @ui-background-color-darker;
			margin-bottom:10px;
		}
		.tl-wikipedia-source {
			//margin-bottom:@base-space;
			font-size: 13px;
			line-height: 15px;
			font-style: italic;
			//margin-top:10px;
			margin-top:3px;
			display:block;
			//margin-left:70px;
			color:fadeout(@color-dark, 50);
		}
		h4 {
			margin-top:0px;
			//border-bottom: 1px solid @color-line;
			margin-bottom:0px;
			//margin-left:70px;
		}
		h4 a {
			color:@color-dark; //@color-theme;
			text-decoration: none;
		}

		p {
			font-size: 13px;
			line-height: 19px;
		}

	}

}
.tl-slide-media-only {
	.tl-media {
		.tl-media-wikipedia {
			border-right: 0;
			border-top: 1px solid @ui-background-color-darker;
			width:80%;
			margin-left:auto;
			margin-right:auto;
			margin-top:25px;
			padding-top:25px;
		}
	}
}
.tl-slide.tl-full-image-background, .tl-slide.tl-full-color-background {
	.tl-media {
		.tl-media-wikipedia {
			padding:20px;
			.background-color-opacity(0,0,0, 60);
			.border-radius(7px);
			h4 a {
				.slide-text-shadow();
			}
			a:hover{
				text-decoration: underline;
				color: @color-theme;
			}
			.tl-wikipedia-title {
				border-color: fadeout(@ui-background-color-darker,75);
			}
			.tl-wikipedia-source {
				color:fadeout(@ui-background-color-darker,15);
			}
		}
	}
}
// Mobile, iPhone and skinny
.tl-mobile.tl-skinny, .tl-skinny{
	.tl-media {
		.tl-media-wikipedia {
			margin-left: 10px;
			margin-right: 10px;
		}

	}
}
