/* StorySlider
================================================== */

/* SLIDER CONTAINERS
================================================== */
.tl-storyslider {
	width:100%;
	height:100%;
	overflow:hidden;
	.user-select(none);
	position:relative;
	box-sizing:content-box;
	//.box-shadow(1px 1px 7px rgba(0,0,0,.30));

	z-index:8;
	img, embed, object, video, iframe {
		max-width: 100%;
		position:relative;
	}
	.tl-slider-background {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;

	}
	.tl-slider-touch-mask {
		width:100%;
		height:100%;
		z-index:25;

		top:0px;
		left:0px;
		position: absolute;
	}
	.tl-slider-container-mask {
		text-align: center;
		width: 100%;
		height: 100%;
		//overflow: scroll;
		position: relative;
		z-index:5;
		.tl-slider-container {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			text-align: center;

			.tl-slider-item-container {
				width: 100%;
				height: 100%;
				display:table-cell;
				vertical-align:middle;

			}
		}
	}

}

/* Skinny
================================================== */
.tl-skinny {
	.tl-storyslider {
		.tl-slider-container-mask {

			.tl-slider-container {
				.tl-slider-item-container {

				}
			}
		}

	}
}

/* Mobile, iPhone
================================================== */
.tl-mobile {
	.tl-storyslider {

	}
}

/* Mobile, iPhone and skinny
================================================== */
.tl-mobile.tl-skinny {
	.tl-storyslider {

	}
}
