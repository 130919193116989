.tl-media {
	.tl-media-iframe {
		width: 100%;
		height: 100%;
		iframe {
			width: 100%;
			height: 100%;
		}
	}

}

// Mobile, iPhone and skinny
@media only screen and (max-width: 500px), only screen and (max-device-width: 500px) {
	.tl-media {
		.tl-media-iframe {
		
		}

	}
}