.tlanimate {
	-webkit-transform: translateZ(0);
	-webkit-perspective: 1000;
	-webkit-backface-visibility: hidden;
}
.tl-animate {
	.property-animation(all, @animation-duration, @animation-ease);
	.animation-timing-cubic-bezier();
}

.tl-animate-opacity {
	.property-animation(opacity, @animation-duration, @animation-ease);
	.animation-timing-cubic-bezier();
}