.tl-media {
	.tl-media-image {
		
	}

}

.tl-mobile.tl-skinny {
	.tl-media {
		.tl-media-image {
			max-height:250px !important;
		}

	}
}