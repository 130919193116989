.tl-media {
	.tl-media-instagram {
		
	}

}


.tl-mobile.tl-skinny {
	.tl-media {
		.tl-media-instagram {
			max-height:250px !important;
		}

	}
}